<template>
<section class="menuManagement">
    
    <div class="page-header">
        <h3 class="page-title">Menu Management </h3>
        <nav aria-label="breadcrumb">
            <div class="float-right">
                <router-link class="btn btn btn-add btn-primary" :to="`/website-management/add-menu`">Add New
                </router-link>
                <router-link class="btn btn btn-add btn-primary" :to="`/website-management/edit-menu`">Edit New
                </router-link>
            </div>
        </nav>
    </div>
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
            <div class="row project-list-showcase" v-if="data">

                <div class="col-md-12">
                    <vue-drag-tree :data="data" @current-node-clicked="curNodeClicked" @drag-end="dragEndHandler" @drop="dropHandler">
                    </vue-drag-tree>
                </div>

            </div>
        </div>
    </div>
</section>
</template>

<script>

export default {
    name: "view_category",
    components: {
        
    },
    data() {
        return {
            data: [{
                    name: 'Home',
                    id: 1,
                    children: [],

                },
                {
                    name: 'Contact us',
                    id: 2,
                    children: [],

                },
                {
                    name: 'Terms & Conditions',
                    id: 3,
                    children: [],

                },
                {
                    name: 'FAQs',
                    id: 4,
                    children: [],

                },
                {
                    name: 'Privacy Policy',
                    id: 5,
                    children: [],
                    
                },
                {
                    name: 'Blog',
                    id: 6,
                    expanded: true,
                    // children: [{
                    //         name: 'Blog 1',
                    //         id: 7,
                    //         expanded: true  
                    //     },
                    //     {
                    //         name: 'Blog 2',
                    //         id: 8,
                    //         expanded: true,  
                    //         children: [],

                    //     }
                    // ]
                }
            ],
        };
    },

    methods: {
        toggleNodeExpansion(node) {
            node.expanded = !node.expanded; // Toggle the expanded state
        },
        curNodeClicked(model, component) {
            this.toggleNodeExpansion(model); // Example: Toggle expansion on node click
        },
        allowDrag(model, component) {
            if (model.name === 'Node 0-1') {
                // can't be dragged
                return false;
            }
            // can be dragged
            return true;
        },
        allowDrop(model, component) {
            if (model.name === 'Node 2-2') {
                // can't be placed
                return false;
            }
            // can be placed
            return true;
        },
        curNodeClicked(model, component) {
            // console.log('curNodeClicked', model, component);
        },
        dragHandler(model, component, e) {
            // console.log('dragHandler: ', model, component, e);
        },
        dragEnterHandler(model, component, e) {
            // console.log('dragEnterHandler: ', model, component, e);
        },
        dragLeaveHandler(model, component, e) {
            // console.log('dragLeaveHandler: ', model, component, e);
        },
        dragOverHandler(model, component, e) {
            // console.log('dragOverHandler: ', model, component, e);
        },
        dragEndHandler(model, component, e) {
            // console.log('dragEndHandler: ', model, component, e);
        },
        dropHandler(model, component, e) {
            // console.log('dropHandler: ', model, component, e);
        }
    }
};
</script>

<style scoped>

</style>
